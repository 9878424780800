import api from "common/src/api"

const cache = {};

export function getFirstLevelCategories(categories) {
    return categories.filter(category => category.id.indexOf("/") === -1);
}

export function getSubCategories(categories, parentId) {
    return categories.filter(category => category.id.startsWith(parentId + "/"));
}

function mergeById(categories) {
    const map = {};
    const list = [];
    for (const category of categories) {
        if (!map[category.id]) {
            map[category.id] = {
                id: category.id,
                name: category.display_name || category.name,
            };
            list.push(map[category.id]);
        }
        else {
            map[category.id].name += ' & ' + (category.display_name || category.name);
        }
    }
    return list;
}

export async function loadCategories({ gender = "female" }) {

    if (cache[gender]) {
        return {
            data: cache[gender],
            error: null,
            hasMore: false
        }
    }

    if (!gender) {
        return {
            data: [],
            error: null,
            hasMore: false
        }
    }

    const data = await api.catalogue.get("/categories", {
        queryStringParameters: {
            gender
        }
    });

    const categories = mergeById(data);

    if (categories && Array.isArray(categories)) {
        cache[gender] = categories;
        return {
            data: categories,
            error: null,
            hasMore: false
        }
    }
    else {
        return {
            data: [],
            error: "Failed to fetch categories",
            hasMore: false
        }
    }
}
