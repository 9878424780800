import { Flex } from "antd"
import { useCallback, useContext } from "react"
import { App as AntApp } from "antd"

import { GenderSelect } from "common/src/refactor/components/catalogue/filter/GenderSelect"
import { RegionSelect } from "common/src/refactor/components/catalogue/filter/RegionSelect"
import { SortBySelect } from "common/src/refactor/components/catalogue/filter/SortBySelect"
import { SaleSelect } from "common/src/refactor/components/catalogue/filter/SaleSelect"

import { FilterApiContext } from "common/src/refactor/lib/FilterApi"
import useContextState from "common/src/refactor/hooks/useContextState"


export default function PartitionToolbar({ toolbarSize = "large" }) {
    const { modal } = AntApp.useApp();

    const filterApi = useContext(FilterApiContext);
    const [gender, setGender] = useContextState("gender", filterApi);
    const [region, setRegion] = useContextState("region", filterApi);
    const [sale, setSale] = useContextState("sale", filterApi);
    const [sortby, setSortby] = useContextState("order_by", filterApi);

    const onRegionChange = useCallback(
        (value) => {
            setRegion(value);
            modal.info({
                title: "Delivery location updated",
                content: "All products will now be displayed in local currency. Some prices may vary due to taxes and currency updates. Shipping times may vary based on location.",
                closable: false,
                icon: null,
            });
        },
        [setRegion, modal]
    );


    return (
        <Flex gap="0.5rem" align="center" justify="flex-start" wrap="wrap">
            <Flex gap="0.5rem" align="center">
                <p>Gender:</p>
                <GenderSelect
                    size={toolbarSize}
                    popupMatchSelectWidth={false}
                    value={gender}
                    onChange={setGender} />
            </Flex>
            <Flex gap="0.5rem" align="center">
                <p>Ships to:</p>
                <RegionSelect
                    size={toolbarSize}
                    popupMatchSelectWidth={false}
                    value={region}
                    onChange={onRegionChange} />
            </Flex>
            <Flex gap="0.5rem" align="center">
                <p>Shop by:</p>
                <SaleSelect
                    size={toolbarSize}
                    popupMatchSelectWidth={false}
                    value={sale}
                    onChange={setSale} />
            </Flex>
            <Flex gap="0.5rem" align="center">
                <p>Sort by:</p>
                <SortBySelect
                    size={toolbarSize}
                    popupMatchSelectWidth={false}
                    value={sortby || ""}
                    onChange={setSortby} />
            </Flex>
        </Flex>
    )
}