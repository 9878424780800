import client from "common/src/api/hasura/client"
import getUserContext from "common/src/api/getUserContext"
import gql from "graphql-tag"
import { prepareGraph } from "common/src/api/hasura/add"
import currencyExchange from "common/src/lib/currencyExchange"
import normalizeProduct from "common/src/api/normalize/product"
import generator from "common/src/api/hasura/generate"
import user from "common/src/user"
import hub from "common/src/hub"
import store from "app/store"
import api from "app/api"

const productGraph = `
    id
    name
    description
    url
    image
    images
    price
    salePrice
    currency
    lookId
    catalogueId
    retailer
    availability
    sizes
    ---reactionFields---
    ---productFields---
`;

const productReactionsGraph = `
    customerReactions(where: { userId: { _eq: "#reactionUserId" }}) {
        id
        reaction
        reactedAt
        userId
        productId
    }
`

const { query, queryWithCount } = generator.list("Product");



const assignStock = (products, stock, retailerCountries,
    currentCurrency, exchangeRates) => {
    const stockMap = {};
    stock.forEach(entry => {
        if (!stockMap[entry.productId]) {
            stockMap[entry.productId] = [];
        }
        stockMap[entry.productId].push(entry);
    });

    products.forEach(product => {
        product.stock = null;
        product.currencyConverted = false;
        const id = product.id;
        const countries = retailerCountries ? [].concat(retailerCountries[product.retailer]) : [];

        if (stockMap[id]) {
            const stock = stockMap[id];
            const country = countries ?
                countries.find(c => stock.find(s => s.region === c)) :
                null;
            product.stock = (country ? stock.find(s => s.region === country) : null) ||
                stock.find(s => s.region === "GB") ||
                stock.find(s => s.region === "UK");

            if (product.stock) {
                product.stock.currencyConverted = false;

                if (currentCurrency && product.stock.currency !== currentCurrency) {
                    product.stock.currencyConverted = true;
                    product.stock.price = currencyExchange(product.stock.price,
                        product.stock.currency,
                        exchangeRates);
                    product.stock.salePrice = currencyExchange(product.stock.salePrice,
                        product.stock.currency,
                        exchangeRates);

                    product.stock.currency = currentCurrency;
                }
            }
        }

        if (currentCurrency && !product.stock && product.currency !== currentCurrency) {
            product.price = currencyExchange(product.price,
                product.currency,
                exchangeRates);
            product.salePrice = currencyExchange(product.salePrice,
                product.currency,
                exchangeRates);

            product.currency = currentCurrency;
            product.currencyConverted = true;
        }
    });
};

async function productStockLoader(products) {

    if (user.loggedIn() === null) {
        await hub.promise("app-auth", "stateChange");
    }
    if (user.current() === null) {
        await hub.promise("app-auth", "info-loaded");
    }
    await hub.promise("app-auth", "geo-loaded");

    const state = store.getState();
    // const retailerCountries = state.user.geo.brands;
    let currency = state.user.geo.currency;
    const exchangeRates = state.user.geo.exchange;

    if (state.user.geo.original) {
        currency = null;
    }

    // let retailers = {};
    // let regions = [];
    const productIds = [];

    products.forEach(p => {
        productIds.push(p.id);
        // retailers[p.retailer] = true;
    });

    // retailers = Object.keys(retailers);
    // retailers.forEach(ret => {
    //     const cs = retailerCountries[ret] || [];
    //     // regions = regions.concat(cs);
    // });

    // regions = regions.filter((r, inx, self) => self.indexOf(r) === inx);

    const where = {
        productId: { _in: productIds },
        // region: { _in: regions }
    };

    const stock = await api.productStock.list({ where });

    assignStock(products, stock, retailerCountries, currency, exchangeRates);
}

export async function productLoader(options = {}) {

    let { limit = 20, offset = 0 } = options;
    const { order,
        withCount = false,
        //saved = false, 
        withReactions = false,
        reactionsUserId = null,
        reactions = null,
        withStock = true,
        productFields = "",
        customerId } = options;
    const where = options.where || {};

    if (customerId) {
        where.look = {
            consultation: {
                customerId: { _eq: customerId }
            }
        }
    }
    /*if (withReactions) {
        where.customerReactions = { 
            userId: { 
                _eq: reactionsUserId || user.id() }};
    }*/
    if (reactions) {
        where.customerReactions = where.customerReactions || {};
        where.customerReactions.reaction = { _in: reactions };
    }

    const productQuery = (withCount ? queryWithCount : query)
        .replace('---graph---', productGraph)
        .replace('---productFields---', productFields)
        .replace('---reactionFields---',
            withReactions ?
                productReactionsGraph.replace(
                    '#reactionUserId',
                    reactionsUserId
                ) :
                "")
        .replace('---countGraph---', "count");
    const context = await getUserContext();
    const authRole = context.headers['X-Hasura-Role'];
    const productResponse = await client.query({
        query: gql(prepareGraph(authRole, productQuery)),
        variables: { where, order, limit, offset },
        context
    });

    //console.log(productResponse)

    const items = productResponse.data["Product"];
    const count = withCount ?
        productResponse.data['Product_aggregate']['aggregate']['count'] :
        0;

    const products = items.map(normalizeProduct);

    if (withStock) {
        await productStockLoader(products);
    }

    return withCount ? { products, count } : products;
}

export default productLoader