import { useCallback } from "react";
import useKey from "./useKey";


export default function useContextState(name, ctx) {

    const value = useKey(name, ctx);

    const setValue = useCallback(
        (value) => {
            if (typeof value === "function") {
                ctx.set(name, value(ctx.get(name)));
            }
            else {
                ctx.set(name, value);
            }
        },
        [name, ctx]
    );

    return [value, setValue];
}