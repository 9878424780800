
import { useMemo } from "react"
import { shopByOptions } from "common/src/refactor/lib/FilterApi";
import Select from "common/src/refactor/components/ant/Select"

const options = shopByOptions.map(opt => {
    return {
        key: opt.value,
        value: opt.value,
        label: opt.name
    }
})

export function SaleSelect({ className, ...props }) {
    const cls = useMemo(
        () => ["selector-sale", className || ""].join(" "),
        [className]
    );

    return (
        <Select
            className={cls}
            placeholder="Full price and Sale price"
            options={options}
            {...props} />
    )
}
