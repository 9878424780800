
function FeedMessages({ messages, type }) {

    const filteredMessages = type ?
        messages.filter(message => message.type === type) :
        messages;

    if (filteredMessages.length === 0) {
        return null;
    }

    return (
        <div className="feed-messages">
            {filteredMessages.map((message, i) => (
                <div key={i} className={`feed-message feed-message-${message.type}`}>
                    {message.title && <h3>{message.title}</h3>}
                    <p>{message.message}</p>
                </div>
            ))}
        </div>
    );
}

export default FeedMessages