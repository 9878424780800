
import { useEffect, useMemo, useState, useCallback } from "react"

import { useSelector } from "react-redux"
import { loadFacets } from "common/src/actions/catalogue"
import useUpdateEffect from "common/src/hooks/useUpdateEffect";

function retailer2option(retailer) {
    return {
        key: retailer.id,
        label: retailer.name,
        value: retailer.id,
        commission: retailer.commissionRate
    }
}

export default function useRetailerSelector({
    onItemsChange, itemToOption } = {}) {

    const [query, setQuery] = useState("");
    const isLoading = useSelector(s => s.catalogue.ui.retailers.loading);
    const retailersList = useSelector(s => s.catalogue.data.retailers);

    const search = useCallback(
        (query) => {
            setQuery(query);
        },
        []
    );

    const retailers = useMemo(
        () => retailersList.filter(r => {
            if (r.visible === false) {
                return false;
            }
            if (query) {
                return r.name.toLowerCase().includes(query.toLowerCase());
            }
            return true;
        }),
        [retailersList, query]
    );

    const options = useMemo(
        () => retailers.map(itemToOption || retailer2option),
        [retailers, itemToOption]
    );

    useUpdateEffect(
        () => {
            onItemsChange?.(retailersList);
        },
        [retailersList]
    );

    useEffect(
        () => void loadFacets(),
        []
    );

    return {
        options,
        isLoading,
        search
    }
}