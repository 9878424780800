import { useMemo } from "react"
import { useSelector } from "react-redux"

import Select from "common/src/refactor/components/ant/Select"

import { sortByOptions } from "common/src/refactor/lib/FilterApi"

const options = sortByOptions.map(p => ({
    key: "" + p.value,
    value: p.value,
    label: p.name
}));

export function SortBySelect({ className, currency, ...props }) {

    const loading = useSelector(s => s.catalogue.ui.loading);

    const cls = useMemo(
        () => ["selector-price", className || ""].join(" "),
        [className]
    );

    return (
        <Select
            className={cls}
            placeholder="Sort by"
            options={options}
            loading={loading}
            {...props} />
    )
}
