// import useUpdateEffect from "common/src/hooks/useUpdateEffect";
import { useMemo, useEffect } from "react";
import { priceRanges } from "common/src/refactor/lib/FilterApi";


function price2option(item, currency) {
    return {
        key: item.value,
        value: currency.toLowerCase() + "/" + item.value,
        label: currency + item.name
    };
}

export default function usePriceSelector({ currency, onItemsChange } = {}) {

    const options = useMemo(
        () => priceRanges.map(p => price2option(p, currency)),
        [currency]
    );

    useEffect(
        () => {
            if (onItemsChange) {
                onItemsChange(options.map(o => ({
                    name: o.label,
                    id: o.value
                })));
            }
        },
        [options, onItemsChange]
    )

    return { options };
}