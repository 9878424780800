
import { useMemo } from "react"
import { shippingRegions } from "common/src/refactor/lib/FilterApi";

import Select from "common/src/refactor/components/ant/Select"

const options = shippingRegions.map(r => ({
    key: r.value,
    value: r.value,
    label: r.name
}))

export function RegionSelect({ className, labelPrefix, ...props }) {
    const cls = useMemo(
        () => ["selector-region", className || ""].join(" "),
        [className]
    );

    const localOptions = useMemo(
        () => {
            if (!labelPrefix) {
                return options;
            }
            return options.map(p => ({
                key: p.key,
                value: p.value,
                label: labelPrefix + " " + p.label
            }));
        },
        [labelPrefix]
    );

    const placeholder = labelPrefix ? labelPrefix + " All Regions" : "All Regions";

    return (
        <Select
            className={cls}
            placeholder={placeholder}
            options={localOptions}
            {...props} />
    )
}
