
import { loadCategories } from "common/src/actions/catalogue/categories";
import useQuery from "common/src/refactor/hooks/useQuery";

export default function useCategorySelector({ gender }) {
    const { data: categories, isLoading } = useQuery(
        loadCategories,
        [gender],
        {
            initialData: [],
            params: {
                gender
            }
        },
        [gender]
    );

    return { categories, isLoading }
}