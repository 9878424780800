import { Flex, Input } from "antd";
import { useCallback, useContext, useState } from "react";
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback";
import Button from "common/src/refactor/components/button/Button";
import { FilterApiContext } from "common/src/refactor/lib/FilterApi";
import { ReactComponent as IconClose } from "common/src/svg/close.svg"
import { ReactComponent as IconSearch } from "common/src/svg/search.svg"
// import useDualState from "common/src/refactor/hooks/useDualState";
import useInputHandler from "common/src/refactor/hooks/userInputHandler";
// import { filterQueryParams } from "common/src/refactor/lib/catalogue";
import useContextState from "common/src/refactor/hooks/useContextState";

export default function FeedSearchBar({
    onClose,
    searchButtonType = "primary"
}) {

    const filterApi = useContext(FilterApiContext);
    const [filterQuery, setFilterQuery] = useContextState("query", filterApi);
    const [query, setQuery] = useState(filterQuery);
    const onQueryChange = useInputHandler(setQuery);
    const onSearch = useCallback(
        async (value, e, { source }) => {
            if (source === "clear") {
                setFilterQuery(undefined);
            }
            setFilterQuery(query);
        },
        [setFilterQuery, query]
    );

    const onClear = useCallback(
        () => {
            setFilterQuery(undefined);
        },
        [setFilterQuery]
    );

    const onCloseClick = useSwallowEventCallback(
        () => onClose(),
        [onClose]
    );

    return (
        <Flex align="center" gap="1rem">
            <Input.Search
                placeholder="Search by product, brand, retailer or URL"
                size="large"
                enterButton={
                    <Button
                        // Component="a"
                        onClick={e => e.preventDefault()}
                        size="large"
                        Icon={IconSearch}
                        text="Search"
                        type={searchButtonType} />
                }
                allowClear
                onChange={onQueryChange}
                onClear={onClear}
                value={query}
                onSearch={onSearch} />
            {onClose &&
                <a href="/#"
                    className="feed-dialog-close"
                    onClick={onCloseClick}>
                    <IconClose />
                </a>}
        </Flex>
    )
}