
import { Flex } from "antd"
import { useCallback, useContext } from "react"

import DesignerExpandable from "common/src/refactor/components/catalogue/filter/DesignerExpandable"
import RetailerExpandable from "common/src/refactor/components/catalogue/filter/RetailerExpandable"
import PriceExpandable from "common/src/refactor/components/catalogue/filter/PriceExpandable"
import CategoryExpandable from "common/src/refactor/components/catalogue/filter/CategoryExpandable"
import SelectedFilters from "common/src/refactor/components/catalogue/SelectedFilters"

import { FilterApiContext } from "common/src/refactor/lib/FilterApi"
import useContextState from "common/src/refactor/hooks/useContextState"
import useKey from "common/src/refactor/hooks/useKey"


export default function SideFilters() {

    const filterApi = useContext(FilterApiContext);
    const [retailer, setRetailer] = useContextState("retailer", filterApi);
    const [designer, setDesigner] = useContextState("designer", filterApi);
    const [category, setCategory] = useContextState("category", filterApi);
    const [price, setPrice] = useContextState("price", filterApi);
    const [, setDesignerItems] = useContextState("designerItems", filterApi);
    const [, setRetailerItems] = useContextState("retailerItems", filterApi);
    const [, setPriceItems] = useContextState("priceItems", filterApi);
    const [, setCategoryItems] = useContextState("categoryItems", filterApi);
    const gender = useKey("gender", filterApi);
    const currency = useKey("convert_to_currency", filterApi);

    const onRemoveFilter = useCallback(
        (type, item) => {
            if (type === "designer") {
                if (designer && Array.isArray(designer)) {
                    setDesigner(designer.filter(d => d !== item.id));
                }
                else {
                    setDesigner(undefined);
                }
            }
            else if (type === "retailer") {
                setRetailer(undefined);
            }
            else if (type === "price") {
                setPrice(undefined);
            }
            else if (type === "category") {
                setCategory(undefined);
                // if (Array.isArray(category)) {
                //     setCategory(category.filter(c => c !== item.id));
                // }
                // else if (category) {
                //     if (category.indexOf("/") === -1) {
                //         setCategory("");
                //     }
                //     else {
                //         setCategory(category.split("/")[0]);
                //     }
                // }
            }
        },
        [designer, setDesigner, setRetailer, setPrice, category, setCategory]
    );


    return (

        <Flex vertical gap="1rem" className="catalogue-side-filters">

            <div style={{ overflow: "auto" }}>
                <CategoryExpandable
                    gender={gender}
                    value={category}
                    onChange={setCategory}
                    group="side"
                    onItemsChange={setCategoryItems} />
                {category && (
                    <CategoryExpandable
                        id="sub-category"
                        parentId={category ? category.split("/")[0] : null}
                        value={category}
                        gender={gender}
                        group="side"
                        onChange={setCategory} />
                )}
                <DesignerExpandable
                    value={designer}
                    group="side"
                    onItemsChange={setDesignerItems}
                    onChange={setDesigner} />
                <RetailerExpandable
                    group="side"
                    value={retailer}
                    onChange={setRetailer}
                    onItemsChange={setRetailerItems} />
                <PriceExpandable
                    group="side"
                    value={price}
                    currency={currency}
                    onChange={setPrice}
                    onItemsChange={setPriceItems} />
            </div>

            <SelectedFilters onRemove={onRemoveFilter} />

        </Flex>

    )

}