import { useMemo } from "react"
import { Spin } from "antd"
import useHtmlClass from "common/src/hooks/useHtmlClass"

function Page({ children, className, loading = false,
    htmlClassName, passthrough = false,
    spinTip,
    ...rest }) {

    useHtmlClass(htmlClassName);

    const cls = useMemo(
        () => ["page", className || ""].join(" "),
        [className]
    );

    if (passthrough) {
        return children;
    }

    return (
        <div className={cls} {...rest}>
            {children}
            {loading && (
                <Spin
                    tip={spinTip}
                    spinning
                    className="page-spinner"
                    wrapperClassName="page-spinner"
                    children={<>&nbsp;</>} />
            )}
        </div>
    )
}

export default Page