import ExpandableFilter from "common/src/refactor/components/ExpandableFilter";

import usePriceSelector from "./hooks/usePriceSelector";

export default function PriceExpandable({ currency, value, group, onChange, onItemsChange }) {

    const { options } = usePriceSelector({ currency, onItemsChange });

    return (
        <ExpandableFilter
            showAll
            group={group}
            multiple={false}
            searchable={false}
            items={options}
            value={value}
            onChange={onChange}
            plainValue
            name="Price" />
    )
}