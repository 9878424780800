import { useContext, useMemo, useCallback, useState } from "react";
import { FilterApiContext } from "common/src/refactor/lib/FilterApi";
import useKeys from "common/src/refactor/hooks/useKeys";
import { ReactComponent as Close } from "common/src/svg/close.svg"
import { useOn } from "@kuindji/observable-react"

function SelectedItem({ type, item, onRemove }) {

    const onRemoveClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            onRemove?.(type, item);
        },
        [type, item, onRemove]
    );

    return (
        <a className="catalogue-selected-filters-item" href="/#" onClick={onRemoveClick}>
            <span>{item.name}</span>
            <Close />
        </a>
    )
}

export default function SelectedFilters({ onRemove } = {}) {
    const filters = useContext(FilterApiContext);
    const [clearableCount, setClearableCount] = useState(0);
    const { designer = [], designerItems = [],
        retailer, retailerItems = [],
        price, priceItems = [],
        category, categoryItems } = useKeys(
            ["designer", "designerItems",
                "retailer", "retailerItems",
                "price", "priceItems",
                "category", "categoryItems"],
            filters
        );

    const updateClearableCount = useCallback(
        () => {
            setClearableCount(filters.getClearableFiltersCount());
        },
        [filters]
    );

    useOn(filters, "change", updateClearableCount);

    const selectedItems = useMemo(
        () => {
            const items = [];
            if (designer) {
                for (const value of designer) {
                    const d = designerItems.find(d => d.id === value);
                    if (d) {
                        items.push({
                            type: "designer",
                            item: d
                        });
                    }
                }
            }

            if (retailer) {
                if (typeof retailer === "string") {
                    const r = retailerItems.find(r => r.id === retailer);
                    if (r) {
                        items.push({
                            type: "retailer",
                            item: r
                        });
                    }
                }
                else if (Array.isArray(retailer)) {
                    for (const value of retailer) {
                        const r = retailerItems.find(r => r.id === value);
                        if (r) {
                            items.push({
                                type: "retailer",
                                item: r
                            });
                        }
                    }
                }
            }

            if (price) {
                if (typeof price === "string") {
                    const p = priceItems.find(p => p.id === price);
                    if (p) {
                        items.push({
                            type: "price",
                            item: p
                        });
                    }
                }
                else if (Array.isArray(price)) {
                    for (const value of price) {
                        const p = priceItems.find(p => p.id === value);
                        if (p) {
                            items.push({
                                type: "price",
                                item: p
                            });
                        }
                    }
                }
            }

            if (category) {
                if (typeof category === "string") {
                    const c = categoryItems?.find(c => c.id === category);
                    if (c) {
                        items.push({
                            type: "category",
                            item: c
                        });
                    }
                }
                else if (Array.isArray(category)) {
                    for (const value of category) {
                        const c = categoryItems?.find(c => c.id === value);
                        if (c) {
                            items.push({
                                type: "category",
                                item: c
                            });
                        }
                    }
                }
            }

            return items;
        },
        [designer, designerItems,
            retailer, retailerItems,
            price, priceItems,
            category, categoryItems]
    );

    const onRemoveItem = useCallback(
        (type, item) => {
            onRemove?.(type, item);
        },
        [onRemove]
    );

    const onClearAllClick = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            filters.resetClearableFilters();
        },
        [filters]
    );

    if (!selectedItems.length) {
        return null;
    }

    return (
        <div className="catalogue-selected-filters">
            {selectedItems.map((item, i) => (
                <SelectedItem key={i} {...item} onRemove={onRemoveItem} />
            ))}
            {clearableCount > 1 && (
                <a href="/#"
                    onClick={onClearAllClick}
                    className="catalogue-selected-filters-clear-all">Clear all</a>
            )}
        </div>
    )
}