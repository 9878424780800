import ExpandableFilter from "common/src/refactor/components/ExpandableFilter";
import useRetailerSelector from "./hooks/useRetailerSelector";
import { useCallback } from "react";
import { Flex } from "antd";

export default function RetailerExpandable({ value = [], group, onChange, onItemsChange }) {

    const { options, isLoading, search } = useRetailerSelector({ onItemsChange });

    const renderLabel = useCallback(
        (item) => {
            if (!item) {
                return null;
            }
            return (
                <Flex justify="space-between" align="center">
                    <span>{item.label}</span>
                    <span className="expandable-filter-badge">{item.commission}%</span>
                </Flex>
            )
        },
        []
    );

    return (
        <ExpandableFilter
            searchable={false}
            showAll
            group={group}
            name="Retailer"
            searchPlaceholder="Search retailers"
            multiple={false}
            items={options}
            loading={isLoading}
            onSearch={search}
            value={value}
            onChange={onChange}
            renderOption={renderLabel} />
    );

}