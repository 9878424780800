import ExpandableFilter from "common/src/refactor/components/ExpandableFilter";
import useCategorySelector from "./hooks/useCategorySelector";
import { useMemo, useEffect, useRef } from "react";
import { getFirstLevelCategories, getSubCategories } from "common/src/actions/catalogue/categories";
import { useCallback } from "react";

function categoryToOption(d) {
    return { ...d, label: d.name, key: d.id, value: d.id }
}

export default function CategoryExpandable({
    id, value, gender, parentId, group,
    onChange, onItemsChange
}) {

    const { categories: allCategories, isLoading } = useCategorySelector({ gender });
    const cmpRef = useRef(null);

    const correctedValue = useMemo(
        () => {
            if (!value) {
                return value;
            }
            if (parentId) {
                return value;
            }

            if (Array.isArray(value)) {
                return value[0].split("/")[0];
            }
            else {
                return value.split("/")[0];
            }
        },
        [value, parentId]
    );

    const categories = useMemo(
        () => {
            if (!allCategories) {
                return [];
            }
            if (!parentId) {
                return getFirstLevelCategories(allCategories);
            }
            return getSubCategories(allCategories, parentId);
        },
        [allCategories, parentId]
    );

    const options = useMemo(
        () => categories.map(categoryToOption),
        [categories]
    );

    const name = useMemo(
        () => {
            if (!parentId) {
                return "Category";
            }
            const parent = allCategories.find(c => c.id === parentId);
            return parent?.name || "Category";
        },
        [parentId, value, correctedValue, allCategories]
    );

    // const proxyOnChange = useCallback(
    //     (value) => {
    //         onChange?.(value);
    //         if (parentId) {
    //             // console.log("on change", value)
    //             return;
    //         }
    //     },
    //     [onChange, cmpRef, parentId]
    // );

    useEffect(
        () => onItemsChange?.(allCategories),
        [allCategories]
    );

    useEffect(
        () => {
            if (value && value.indexOf("/") === -1 && cmpRef.current) {
                cmpRef.current.toggle();
            }
        },
        [value]
    );

    useEffect(
        () => {
            if (parentId && cmpRef.current) {
                cmpRef.current.toggle();
            }
        },
        []
    );

    return (
        <ExpandableFilter
            ref={cmpRef}
            id={id}
            group={group}
            name={name}
            showAll={true}
            multiple={false}
            searchable={false}
            plainValue={true}
            showClear={!parentId}
            allValue={parentId || null}
            loading={isLoading}
            items={options}
            value={correctedValue}
            onChange={onChange} />
    )
}
