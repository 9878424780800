import { Spin, Flex } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"

import ProductCard from "common/src/refactor/components/catalogue/product/Card"
import CatalogueProductDrawer from "common/src/refactor/components/catalogue/product/Drawer"
import EmptyMessage from "common/src/refactor/components/EmptyMessage"
import ProductContextMenu from "app/components/product/ContextMenu"
import BackToTop from "common/src/refactor/components/catalogue/BackToTop"
import { ReactComponent as IconUp } from "common/src/svg/angle-up.svg"
import Button from 'common/src/refactor/components/button/Button'
import FeedMessages from "common/src/refactor/components/catalogue/FeedMessages"
import PartitionToolbar from "common/src/refactor/components/catalogue/PartitionToolbar"
import SideFilters from "common/src/refactor/components/catalogue/SideFilters"
import FeedSearchBar from "common/src/refactor/components/catalogue/SearchBar"

import { FilterApiContextProvider } from "common/src/refactor/lib/FilterApi"

// import { filterQueryParams } from "common/src/refactor/lib/catalogue"
import useProductCatalogue from "common/src/refactor/hooks/useProductCatalogue"
import Spacer from "common/src/refactor/components/Spacer"
import Page from "common/src/refactor/components/Page"
// import useContextData from "common/src/refactor/hooks/useContextData"
// import useDualState from "common/src/refactor/hooks/useDualState"
import useIsMobile from "common/src/refactor/hooks/useIsMobile"
import DownloadApp from "common/src/refactor/components/authenticator/DownloadApp"
import QRTip from "app/components/feed/QR"
import user from "common/src/user"
import api from "common/src/api"
import Banner from "app/components/feed/Banner"

import FilterApi, { queryParamKeys } from "common/src/refactor/lib/FilterApi"
import useFilterToQueryConnection, { getInitialData } from "common/src/refactor/hooks/useFilterToQueryConnection"

function PageFeed() {

    const [searchParams] = useSearchParams();
    const filters = useMemo(
        () => new FilterApi((api) => {
            return getInitialData(api, searchParams, queryParamKeys)
        }),
        // eslint-disable-next-line
        []
    );

    useFilterToQueryConnection(filters, queryParamKeys);

    const isMobile = useIsMobile();
    const [showQR, setShowQR] = useState(false);

    const { products, loadMore, hasMore, loading, loaded, messages, takingTooLong, isError } =
        useProductCatalogue({ filters, setName: "feed", perPage: 24 });

    const onProductClick = useCallback((p) => CatalogueProductDrawer.show({ product: p }), []);

    const userInfo = useCallback(
        async () => {
            const id = user.id();
            const { firstLoggedIn, lastLoggedIn } = await api.user.get(id, 'firstLoggedIn lastLoggedIn');
            const lastDate = new Date(lastLoggedIn);
            const firstDate = new Date(firstLoggedIn);

            if (localStorage.getItem("showQR") === "false") {
                setShowQR(false);
            }
            else if (lastDate - firstDate <= 2 * 60 * 1000) {
                setShowQR(true);
                localStorage.setItem("showQR", true);
            }
        },
        []
    );

    useEffect(() => void userInfo(), [userInfo]);

    if (isMobile) {
        return (
            <DownloadApp />
        )
    }

    return (
        <Page className="page-feed">
            {showQR && <QRTip onClose={setShowQR} />}
            <Banner />
            <FilterApiContextProvider value={filters}>

                <InfiniteScroll
                    className="page-feed-scroller page-feed-scroller-full-page"
                    dataLength={products.length}
                    hasMore={hasMore}
                    next={loadMore}
                >

                    <Flex className="catalogue-layout">

                        <div style={{ position: "sticky", top: 0 }}>
                            <SideFilters />
                        </div>

                        <Flex vertical gap="1rem" className="catalogue-layout-main">
                            <FeedSearchBar />
                            <PartitionToolbar />
                            <FeedMessages messages={messages} />
                            {(loaded &&
                                !loading &&
                                products.length === 0) && (
                                    <EmptyMessage message="No products matched your search" size="small" />
                                )}
                            <Flex vertical gap="1rem">
                                <div className="catalogue-products">
                                    {products.map(p => (
                                        <ProductCard
                                            key={p.uid}
                                            product={p}
                                            onClick={onProductClick}>
                                            <ProductContextMenu product={p} />
                                        </ProductCard>))}


                                </div>
                                {!isError ?
                                    loading ?
                                        <Spin
                                            tip={takingTooLong ? "Sorry, this is taking a bit longer than expected" : undefined}
                                            children={<>&nbsp;</>}
                                            spinning
                                            className="infinite-scroll-spinner"
                                            wrapperClassName="infinite-scroll-spinner-wrapper" /> :
                                        null :
                                    products.length > 0 ?
                                        <FeedMessages messages={messages} type="error" /> :
                                        null}
                            </Flex>
                        </Flex>
                    </Flex>
                </InfiniteScroll>
                <BackToTop>
                    <Button
                        type="secondary"
                        shape="circle"
                        Icon={IconUp}
                        text="Back to top"
                        iconPosition="before" />
                </BackToTop>
                <Spacer size="3rem" />
            </FilterApiContextProvider>
        </Page>
    )
}

export default PageFeed