
import ExpandableFilter from "common/src/refactor/components/ExpandableFilter";
import useDesignerSelector from "./hooks/useDesignerSelector";


export default function DesignerExpandable({ value = [], group, onChange, onItemsChange }) {

    const {
        options, isLoading,
        loadMore, search
    } = useDesignerSelector({ value, onItemsChange });

    return (
        <ExpandableFilter
            searchable
            multiple={true}
            showAll={true}
            loading={isLoading}
            name="Designer"
            group={group}
            searchPlaceholder="Search designers"
            items={options}
            onChange={onChange}
            onSearch={search}
            onLoadMore={loadMore}
            value={value} />
    )
}