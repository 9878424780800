import { useCallback, useState } from "react"
import { App as AntApp } from "antd";
import useDictRef from "common/src/hooks/useDictRef";
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux";
import ConnectionSelector from "common/src/refactor/components/dialog/ConnectionSelector";
import { sendLook } from "common/src/actions/chat";
import { remove as removeLook } from "common/src/actions/look/editor"
import api from "common/src/api";
import hub from "common/src/hub";
import user from "common/src/user"

function useLookActions({ look, consultation, fromHref,
    onSend, onDuplicate, onDelete, onHide }) {

    const { modal, message } = AntApp.useApp();
    const [sending, setSending] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [duplicating, setDuplicating] = useState(false);
    const [hiding, setHiding] = useState(false);
    const [duplicatedId, setDuplicatedId] = useState(null);
    const cons = useSelector(s => {
        if (consultation) {
            return consultation;
        }
        if (s.consultationPage.data.cons?.id === look.consultationId) {
            return s.consultationPage.data.cons
        }

        return null
    })
    const navigate = useNavigate();

    const ref = useDictRef({ duplicatedId });

    const onEditClick = useCallback(
        (e) => {
            e?.domEvent?.stopPropagation();

            const from = fromHref ||
                (cons ? `/styling/${cons.id}` : "");

            navigate(`/look-editor/${look.id}?from=${encodeURIComponent(from)}`);
        },
        [look, cons, navigate, fromHref]
    );

    const onSendClick = useCallback(
        async (e) => {
            e?.domEvent?.stopPropagation();

            const conns = await ConnectionSelector.show({
                multiple: false,
                connectionId: cons.customerId,
                showSearch: false
            });

            if (conns) {
                setSending(true);
                await sendLook(look.id, cons.id);

                message.success({
                    content: `Look sent to ${conns[0].givenName} ${conns[0].familyName}`,
                    icon: <></>
                });

                onSend && onSend();
                setSending(false);
            }
        },
        [look, onSend, cons, message]
    );

    const deleteLook = useCallback(
        async () => {
            setDeleting(true);

            await removeLook(look.id);

            message.success({
                content: `${look.title || "Look"} has been deleted`,
                icon: <></>
            });

            onDelete && onDelete();
        },
        [look, message, onDelete]
    );

    const onDeleteClick = useCallback(
        (e) => {
            e?.domEvent?.stopPropagation();

            modal.confirm({
                closable: true,
                icon: null,
                width: 540,
                title: "Delete look?",
                content: "You can restore this look in 'Recently Deleted' from your profile within 30 days. After that, it will be permanently deleted.",
                okText: "DELETE NOW",
                cancelButtonProps: {
                    style: {
                        display: 'none'
                    }
                },
                okButtonProps: {
                    style: {
                        textTransform: "uppercase"
                    }
                },
                onOk: async () => {
                    await deleteLook()
                }
            })
        },
        [deleteLook, modal]
    );

    const onUndoPress = useCallback(
        async () => {
            await removeLook(ref.duplicatedId);
        },
        [ref.duplicatedId]
    );

    const onDuplicateClick = useCallback(
        async (e) => {

            e?.domEvent?.stopPropagation();

            setDuplicating(true);

            message.success({
                content: "Duplicating...",
                duration: 0,
                icon: <></>
            });

            const { id } = await api.backend.post("/look/copy", {
                body: {
                    lookId: look.id,
                    targetConsultationId: cons.id
                }
            });

            onDuplicate && onDuplicate(look, id);

            setDuplicatedId(id);
            setDuplicating(false);

            hub.dispatch("look", "created", {
                id,
                consultationId: cons.id
            })

            message.destroy();
            message.success({
                content: (
                    <>
                        Look has been duplicated to {cons.title}.
                        <button
                            className="notice-button-link"
                            onClick={onUndoPress}
                            children="UNDO" />
                    </>
                ),
                icon: <></>

            })
        },
        [look, onDuplicate, onUndoPress, message, cons]
    );

    const onHideToggle = useCallback(
        async (e) => {

            console.log("onHideToggle")
            console.log(e)

            e?.domEvent?.stopPropagation();
            e?.domEvent?.preventDefault();


            if (cons?.hidden || !cons?.customerId) {
                console.log("aborting")
                return;
            }

            setHiding(true);

            if (look.published) {
                await api.look.update(look.id, { published: false, publishedAt: null })
            } else {
                await api.look.update(look.id, { published: true, publishedAt: (new Date()).toISOString() });
            }

            setHiding(false);

            hub.dispatch("look", "updated", { id: look.id });

            onHide && onHide(look.published);
        },
        [look, cons, onHide]
    );

    const lookApi = {
        onEditClick,
        onSendClick,
        onDeleteClick,
        onDuplicateClick,
        onHideToggle,

        loading: sending || deleting || duplicating || hiding
    }

    if (user.isOnly("Admin")) {
        lookApi.onSendClick = null;
    }

    return lookApi;
}

export default useLookActions