import useDictRef from "common/src/hooks/useDictRef";
import { useSearchParams } from "react-router-dom";
import { useEffect, useCallback } from "react";
import { useOn } from "@kuindji/observable-react";

function queryParams2data(searchParams, queryParamKeys, api) {
    const data = {};
    for (const key of queryParamKeys) {
        data[key] = api.unserialize(key, searchParams.get(key));
    }
    return data;
}

export function getInitialData(api, searchParams, queryParamKeys) {
    return queryParams2data(searchParams, queryParamKeys, api);
}

export default function useFilterToQueryConnection(api, queryParamKeys) {
    const [searchParams, setSearchParams] = useSearchParams();
    const ref = useDictRef({ queryParamKeys, api, searchParams, setSearchParams });

    useEffect(
        () => {
            ref.api.set(queryParams2data(
                searchParams,
                ref.queryParamKeys,
                ref.api
            ));
        },
        [searchParams]
    );

    const onKeysChange = useCallback(
        (keys) => {
            const newSearchParams = new URLSearchParams(ref.searchParams);
            let keysFound = false;
            for (const key of keys) {
                if (ref.queryParamKeys.includes(key)) {
                    keysFound = true;
                    const value = ref.api.serialize(
                        key,
                        ref.api.get(key)
                    );
                    if (value !== undefined) {
                        newSearchParams.set(key, value);
                    }
                    else {
                        newSearchParams.delete(key);
                    }
                }
            }

            // console.log(keysFound, keys, newSearchParams.toString());
            if (keysFound) {
                ref.setSearchParams(newSearchParams);
            }
        },
        []
    );

    useOn(api, "change", onKeysChange);
}