import api from "common/src/api"

export async function loadDesigners({ offset = 0, limit, query, id }) {
    let designers;
    const body = { offset }
    limit && (body.limit = limit);
    query && (body.query = query);
    id && (body.id = id);

    if (!id || typeof id === "string") {
        designers = await api.catalogue.get("/designers", {
            queryStringParameters: body
        });
    }
    else {
        designers = await api.catalogue.post("/designers", {
            body
        });
    }


    if (designers && Array.isArray(designers)) {
        return {
            data: designers,
            error: null,
            hasMore: limit && designers.length === limit
        }
    }
    else {
        return {
            data: [],
            error: "Failed to fetch designers",
            hasMore: false
        }
    }
}
