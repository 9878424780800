
import { useMemo } from "react"

import Select from "common/src/refactor/components/ant/Select"

const options = [
    {
        key: "women",
        value: "female",
        label: "Women"
    },
    {
        key: "men",
        value: "male",
        label: "Men"
    }
]

export function GenderSelect({ className, ...props }) {
    const cls = useMemo(
        () => ["selector-gender", className || ""].join(" "),
        [className]
    );

    return (
        <Select
            className={cls}
            placeholder="All"
            options={options}
            {...props} />
    )
}
