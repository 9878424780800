import { useCallback, useState } from "react"
import { useOn } from "@kuindji/observable-react"

function useKey(key, ctx, namespace) {
    const [value, setValue] = useState(() => ctx?.get?.(key, namespace) || null);

    const onChange = useCallback(
        () => ctx ? setValue(ctx?.get?.(key, namespace)) : null,
        [key, ctx, namespace]
    );

    useOn(
        ctx,
        namespace ? `${namespace}:${key}` : key,
        onChange
    );

    return value;
}

export default useKey